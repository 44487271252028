<template>
  <div class="grid grid-cols-3 gap-5">
    <div v-for="field in FIELDS_DETAILS" :key="field?.id">
      <workflow-field
        :id="field?.id?.replaceAll('_', '-')"
        :label="field?.title || ''"
        :value="field?.value || ''"
        :show-tooltip="false"
        click-to-copy
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { format } from "date-fns";
import WorkflowField from "@/components/deals/WorkflowLive/WorkflowLiveField.vue";

import type { PropType } from "vue";
import { computed } from "vue";
import { formatAddress, phoneToE164 } from "@/helpers/formatting";
import { useI18n } from "vue-i18n";
import { useWorkflowFields } from "@/hooks/workflow";
import {
  WORKFLOW_BLOCKS_IDS,
  PERSONAL_INFO_FIELD_IDS
} from "@/helpers/constants/workflow";
import type { OwnerWFL } from "@/models/workflows";
import { useStore } from "vuex";
import type { IAddress } from "@/models/common";

const props = defineProps({
  personal: {
    type: Object as PropType<OwnerWFL | null>
  },
  readonly: {
    type: Boolean,
    default: false
  }
});

const { t } = useI18n();
const { getters } = useStore();

const { isFieldVisible } = useWorkflowFields(WORKFLOW_BLOCKS_IDS.personal_info);

const options = computed<Record<string, string>>(
  () => getters["options/states"]
);

const personalAddresses = computed(() =>
  props.personal?.personal_addresses?.length
    ? props.personal?.personal_addresses.map((address, index) => ({
        id: PERSONAL_INFO_FIELD_IDS.address,
        title:
          index === 0
            ? "Primary " + t("COMMON.ADDRESS")
            : t("COMMON.ADDRESS") + " " + (index + 1),
        value: formatAddress(address as IAddress)
      }))
    : [
        {
          id: PERSONAL_INFO_FIELD_IDS.address,
          title: "Primary " + t("COMMON.ADDRESS"),
          value: ""
        }
      ]
);

const emailAddresses = computed(() => {
  let emailAddresses = ["-"];
  if (props.personal?.personal_email_address?.email_address) {
    emailAddresses = [
      props.personal?.personal_email_address?.email_address,
      ...(props.personal?.personal_email_address?.additional_emails || [])
    ];
  }
  return emailAddresses.map((email, index) => ({
    id: PERSONAL_INFO_FIELD_IDS.email,
    title:
      index === 0
        ? "Primary " + t("COMMON.EMAIL")
        : t("COMMON.EMAIL") + " " + (index + 1),
    value: email
  }));
});

const phones = computed(() => {
  let phones = [{ phone_number: "-", is_primary: true }];
  if (props.personal?.personal_phone_numbers) {
    phones = props.personal?.personal_phone_numbers.map((phone) => {
      return {
        phone_number: phoneToE164(phone.phone_number),
        is_primary: phone.is_primary ?? false
      };
    });
  }
  return phones.map((phone, index) => ({
    id: PERSONAL_INFO_FIELD_IDS.phone_numbers,
    title: phone.is_primary
      ? "Primary " + t("COMMON.PHONE")
      : t("COMMON.PHONE") + " " + (index + 1),
    value: phone.phone_number
  }));
});
const FIELDS_DETAILS = computed(() =>
  [
    {
      id: PERSONAL_INFO_FIELD_IDS.first_name,
      title: t("COMMON.FIRST_NAME"),
      value: props.personal?.personal_first_name
    },
    {
      id: PERSONAL_INFO_FIELD_IDS.last_name,
      title: t("COMMON.LAST_NAME"),
      value: props.personal?.personal_last_name
    },
    {
      id: PERSONAL_INFO_FIELD_IDS.drivers_license_number,
      title: t("PERSON.DRIVERS_LICENSE_NUMBER"),
      value: props.personal?.personal_drivers_license_number
    },
    {
      id: PERSONAL_INFO_FIELD_IDS.drivers_license_State,
      title: t("PERSON.DRIVERS_LICENSE_STATE"),
      value: props.personal?.personal_drivers_license_state
        ? options.value[
            props.personal?.personal_drivers_license_state as string
          ]
        : "-"
    },
    {
      id: PERSONAL_INFO_FIELD_IDS.birth_date,
      title: t("DEALS.DEAL_PROGRESS.PERSONAL_INFO.DOB"),
      value: props.personal?.personal_date_of_birth
        ? format(
            new Date(props.personal.personal_date_of_birth + "T00:00:00"),
            "MM/dd/yyyy"
          )
        : "-"
    },
    ...phones.value,
    ...emailAddresses.value,
    {
      id: PERSONAL_INFO_FIELD_IDS.ssn_itin,
      title: t("DEALS.DEAL_PROGRESS.PERSONAL_INFO.SSN"),
      value: props.personal?.personal_ssn_itin?.ssn
    },
    {
      id: PERSONAL_INFO_FIELD_IDS.ssn_itin,
      title: t("DEALS.DEAL_PROGRESS.PERSONAL_INFO.ITIN"),
      value: props.personal?.personal_ssn_itin?.itin
    },
    {
      id: PERSONAL_INFO_FIELD_IDS.sin,
      title: t("DEALS.DEAL_PROGRESS.PERSONAL_INFO.SIN"),
      value: props.personal?.personal_sin
    },
    ...personalAddresses.value
  ].filter((field) => field?.id && isFieldVisible(field.id))
);
</script>
